import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './share_buttons.module.css'
import useWindowSize from 'react-use/lib/useWindowSize'
import { CSSTransition } from 'react-transition-group' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import {
  EmailButton,
  FacebookButton,
  FacebookMessengerButton,
  InstagramButton,
  LinkButton,
  TwitterButton,
  WhatsappButton,
} from 'betterplace-react-components'
import { PlatformType, SharedButtonsProps } from './types'
import { gaEvent, themeColor } from 'shared'

export const ShareButtons = ({
  status,
  fundraisingEvent,
  mobileOnly,
  gaLabel,
  shape,
  shareUrl,
  withLabel,
  project,
  platforms,
  className,
  asGrid,
}: SharedButtonsProps): JSX.Element | null => {
  const [copyMode, setCopyMode] = useState(false)
  const [copyModeInstagram, setCopyModeInstagram] = useState(false)
  const [left, setLeft] = useState(0)

  const handleResize = () => {
    let bodyLeft = document.querySelector('body')?.getBoundingClientRect().left ?? 0
    if (bodyLeft < 0) bodyLeft = 0
    setLeft(bodyLeft)
  }
  const dim = useWindowSize()
  useEffect(handleResize, [dim])

  const scope = fundraisingEvent ? 'fundraising_events.show.sharing' : 'projects.show.share_buttons'

  const buttonProps = useCallback(
    (platform: string, callback?: () => void) => ({
      beforeOnClick: () => {
        gaEvent('engagement', `click ${platform}_social_sharing_button`, gaLabel)
        callback && callback()
      },

      content: i18n.t(`${platform}_share_label`, { scope }),
      shape,

      // TODO check if shareUrl is really sometimes only optional
      shareUrl: shareUrl ?? '',

      teaser: i18n.t(`${platform}_teaser`, {
        scope,
        defaultValue: i18n.t('default_teaser', { scope }),
      }),

      title: i18n.t(`${platform}_share_label`, { scope }),
      withLabel,

      utmParams: {
        utm_campaign: 'user_share',
        utm_medium: `${gaLabel}`,
        utm_source: platform
          .split('_')
          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
          .join(' '),
      },
    }),
    [gaLabel, scope, shape, shareUrl, withLabel]
  )

  const listItemClasses = useCallback(
    (key: PlatformType) => {
      return mobileOnly?.includes(key) ? 'mobile-only-flex' : ''
    },
    [mobileOnly]
  )

  const facebookAppId =
    (document.head.querySelector("[property='fb:app_id']") as HTMLMetaElement | null)?.content || null

  if (status === 'blocked' || status === 'preparing') return null

  return (
    <>
      <CSSTransition
        in={copyMode}
        timeout={200}
        className={styles.overlay}
        classNames={{
          enter: styles.overlayEnter,
          enterActive: styles.overlayActiveEnter,
          exit: styles.overlayExit,
          exitActive: styles.overlayExitActive,
        }}
        unmountOnExit
        style={{
          left: left,
          top: project ? 'auto' : 0,
          bottom: project ? 0 : 'auto',
        }}
      >
        <div>
          <strong>{i18n.t('misc.copy_alert.headline')}</strong>{' '}
          {copyModeInstagram && (
            <>
              <span>&nbsp;</span>
              <span>{i18n.t('misc.copy_alert.text')}</span>
            </>
          )}
        </div>
      </CSSTransition>
      <ul className={classNames(styles.container, asGrid && styles.grid, `${className || ''}`)}>
        {platforms.includes('facebook') && (
          <li className={listItemClasses('facebook')}>
            <FacebookButton {...buttonProps('facebook')} />
          </li>
        )}
        {platforms.includes('twitter') && (
          <li className={listItemClasses('twitter')}>
            <TwitterButton {...buttonProps('twitter')} />
          </li>
        )}
        {platforms.includes('email') && (
          <li className={listItemClasses('email')}>
            <EmailButton {...buttonProps('email')} color={themeColor('teal-700')} />
          </li>
        )}
        {platforms.includes('link') && (
          <li className={listItemClasses('link')}>
            <LinkButton
              {...buttonProps('link', () => {
                setCopyMode(true)
                setCopyModeInstagram(false)
                setTimeout(() => setCopyMode(false), 2400)
              })}
            />
          </li>
        )}
        {platforms.includes('whatsapp') && (
          <li className={listItemClasses('whatsapp')}>
            <WhatsappButton {...buttonProps('whatsapp')} />
          </li>
        )}
        {platforms.includes('messenger') && (
          <li className={listItemClasses('messenger')}>
            <FacebookMessengerButton {...buttonProps('messenger')} facebookAppId={facebookAppId ?? ''} />
          </li>
        )}
        {platforms.includes('instagram') && (
          <li className={listItemClasses('instagram')}>
            <InstagramButton
              {...buttonProps('instagram', () => {
                setCopyModeInstagram(true)
                setCopyMode(true)
                setTimeout(() => setCopyMode(false), 4000)
              })}
            />
          </li>
        )}
      </ul>
    </>
  )
}
