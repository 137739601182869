import { NetworkStatus } from './types'
import { useEffect, useState } from 'react'

export const useNetworkStatus = (): NetworkStatus => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>('online')

  const checkNetworkStatus = (): void => {
    if (navigator.onLine) {
      setNetworkStatus('online')
    } else {
      setNetworkStatus('offline')
    }
  }

  useEffect(() => {
    checkNetworkStatus()

    window.addEventListener('offline', checkNetworkStatus)
    window.addEventListener('online', checkNetworkStatus)

    return () => {
      window.removeEventListener('offline', checkNetworkStatus)
      window.removeEventListener('online', checkNetworkStatus)
    }
  }, [])

  return networkStatus
}
