import React from 'react'
import { TrustedHtmlProps } from './types'

/**
 * Use this component for and only for inserting HTML we can trust.
 */
export const TrustedHtml = ({ value, as = 'span', ...props }: TrustedHtmlProps): JSX.Element => {
  const Tag = as
  return <Tag dangerouslySetInnerHTML={{ __html: value }} {...props} />
}
