import React, { useEffect } from 'react'
import { StoreRecaptchaTokenProps } from './types'

export const StoreRecaptchaToken = ({
  sitekey,
  action,
  verifyCallback,
  submitCount,
}: StoreRecaptchaTokenProps): null => {
  useEffect(() => {
    window.grecaptcha &&
      window.grecaptcha.ready(() => {
        // this might be too early. We'd rather do it before form submission.
        window.grecaptcha.execute(sitekey, { action }).then(verifyCallback)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount])
  return null
}
