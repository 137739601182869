import React, { useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import style from './captcha.module.css'
import { Localization, WidgetInstance } from 'friendly-challenge'

const LANG_DE_CUSTOM: Localization = {
  text_init: 'Initialisierung …',

  text_ready: 'Anti-Roboter-Verifizierung',
  button_start: 'Hier klicken',

  text_fetching: 'Herausforderung laden …',

  text_solving: 'Verifizierung, dass du ein Mensch bist …',
  text_completed: 'Du bist ein Mensch',
  text_completed_sr: 'Automatische Spamprüfung abgeschlossen',

  text_expired: 'Verifizierung abgelaufen',
  button_restart: 'Erneut starten',

  text_error: 'Verifizierung fehlgeschlagen',
  button_retry: 'Erneut versuchen',
  text_fetch_error: 'Verbindungsproblem mit',
}

const LANG_EN_CUSTOM: Localization = {
  text_init: 'Initializing …',

  text_ready: 'Anti-Robot Verification',
  button_start: 'Click to start verification',

  text_fetching: 'Fetching Challenge …',

  text_solving: 'Verifying you are human …',
  text_completed: 'You are human',
  text_completed_sr: 'Automatic spam check completed',

  text_expired: 'Anti-Robot verification expired',
  button_restart: 'Restart',

  text_error: 'Verification failed',
  button_retry: 'Retry',
  text_fetch_error: 'Failed to connect to',
}

export const Captcha = ({
  siteKey,
  endpoint,
  onSuccess,
  onError,
  onStart,
  onReset,
  resetTrigger,
  fieldName = 'captcha_solution',
}: CaptchaProps) => {
  const container = useRef<HTMLDivElement>(null)
  const widget = useRef<WidgetInstance>()

  const doneCallback = useCallback(
    (solution: string) => {
      onSuccess(solution)
    },
    [onSuccess]
  )

  const errorCallback = useCallback(
    (error: FriendlyCaptchaError) => {
      // get the field value to store information about the current state, i.e.
      // .EXPIRED
      // .ERROR
      // .HEADLESS_ERROR
      // .UNSTARTED
      // .FETCHING
      // .UNFINISHED
      const field = container.current?.querySelector(`input[name="${fieldName}"]`) as
        | HTMLInputElement
        | null
        | undefined
      onError(error, field?.value)
    },
    [fieldName, onError]
  )

  const startedCallback = useCallback(() => {
    if (typeof onStart === 'function') {
      onStart()
    }
  }, [onStart])

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        sitekey: siteKey,
        startMode: 'focus',
        doneCallback: doneCallback,
        errorCallback: errorCallback,
        startedCallback: startedCallback,
        language: i18n.locale === 'en' ? LANG_EN_CUSTOM : LANG_DE_CUSTOM,
        puzzleEndpoint: endpoint,
        solutionFieldName: fieldName,
      })
    }

    return () => {
      if (widget.current != undefined) {
        widget.current.reset()
        if (typeof onReset === 'function') {
          onReset()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTrigger])

  return <div ref={container} className={classNames('frc-captcha', style.container)} />
}
