import { Project, ProjectData } from './types'
import { dataLayer } from '../safe_data_layer'
type ImpressionsProject = Pick<Project, 'title' | 'id'> & {
  carrier?: Pick<DeNullify<Project['carrier']>, 'name' | 'id'> | null
}

export class GtmHelper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static projectList(project: ImpressionsProject, position?: number, list?: any, quantity?: number): [ProjectData] {
    const projectData: ProjectData = { name: project.title ?? '', id: `Project#${project.id}` }
    if (project.carrier) projectData.brand = `Organisation#${project.carrier.id} ${project.carrier.name}`
    if (quantity) projectData.quantity = quantity
    if (position) projectData.position = position
    if (list) projectData.list = list
    return [projectData]
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static pushProjectImpressions(project: ImpressionsProject, position: number, list: any) {
    const impressionData = {
      event: 'eeProductImpression',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: GtmHelper.projectList(project, position, list),
      },
    }
    dataLayer.push(impressionData)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static pushProjectClicks(project: ImpressionsProject, position: number, list: any) {
    const clickData = {
      event: 'eeProductClick',
      ecommerce: {
        click: {
          actionField: { list: list },
          products: GtmHelper.projectList(project, position, list),
        },
      },
    }
    dataLayer.push(clickData)
  }

  static pushProductDetailImpression(project: ImpressionsProject) {
    const detailData = {
      event: 'eeProductDetailImpression',
      ecommerce: {
        detail: {
          products: GtmHelper.projectList(project),
        },
      },
    }
    dataLayer.push(detailData)
  }

  static pushAddToBasket(project: ImpressionsProject) {
    const basketData = {
      event: 'eeAddToBasket',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: GtmHelper.projectList(project, undefined, undefined, 1),
        },
      },
    }
    dataLayer.push(basketData)
  }
}
