import React from 'react'
import { I18nHtml } from '../i18n_html'
import { OverlayTrigger, Popover as RBPopover } from 'react-bootstrap'
import { PopoverIconProps, PopoverProps } from './types'

export const Popover = ({
  placement,
  i18nKey,
  i18nOptions,
  trigger,
  children,
  popoverId,
}: PopoverProps): JSX.Element => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      flip
      popperConfig={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom'],
            },
          },
        ],
      }}
      overlay={
        <RBPopover id={popoverId}>
          <RBPopover.Content>
            {i18nKey ? <I18nHtml as="div" i18nKey={i18nKey} options={i18nOptions} /> : children}
          </RBPopover.Content>
        </RBPopover>
      }
    >
      {trigger}
    </OverlayTrigger>
  )
}

/**
 * TODO trigger was overwritten by the props trigger, establish whether it is the desired behaviour
 */
export const PopoverIcon = ({ icon = '', iconClass, children, ...props }: PopoverIconProps) => (
  <Popover
    trigger={
      <button
        type="button"
        className={iconClass || `generic-popover-icon ${icon}`}
        aria-label="Tooltip"
        data-ci-type="tooltip-trigger"
      />
    }
    {...props}
  >
    {children}
  </Popover>
)

export const PopoverText = ({ trigger, ...props }: PopoverProps) => (
  <Popover trigger={<span>{trigger}</span>} {...props} />
)
