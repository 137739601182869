import useOrientation from 'react-use/lib/useOrientation'
import { themeBreakpoint } from 'shared/helpers/tailwind_theme' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import { useEffect, useMemo, useState } from 'react'

export const useMobile = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const orientation = useOrientation()
  const breakpoint = themeBreakpoint()
  useEffect(() => setWidth(window.innerWidth), [orientation])

  return useMemo(() => width < breakpoint, [width, breakpoint])
}
