import { I18n } from 'i18n-js'
import { missingTranslationStrategy } from 'shared'
import { render } from 'react-dom'

export const initI18n = () => {
  global.i18n = new I18n()
  global.i18n.missingTranslation.register('report', missingTranslationStrategy)
}

/**
 * Asynchronously load locale translations from JSON endpoint.
 * @param locale The language code for the locale to load.
 */
export const loadLocale = async (locale: string) => {
  // @see https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
  const translations = await import(`../../../../config/locales/javascript.${locale}.json`)
  global.i18n.store({ [locale]: translations })
  global.i18n.locale = locale
}

/**
 * Render a React element once the current locale is loaded.
 * @param element React element to render
 * @param container container element to render in
 */
export const renderWithLocale = <P extends {}>(
  element: React.ReactElement<P, string | React.JSXElementConstructor<unknown>> | React.FunctionComponentElement<P>,
  container: Element
) => {
  // Current locale’s translations available?
  if (i18n.translations[i18n.locale]) {
    render(element, container)
  } else {
    i18n.onChange(() => {
      // Not every change means it’s ready. Call recursive to include the check again.
      renderWithLocale(element, container)
    })
    container.innerHTML = '…'
  }
}
