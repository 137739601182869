/**
 * Every Layout: The Reel
 * @source https://every-layout.dev/layouts/reel/
 * @module reel-l
 * @description
 * A custom element for creating a responsive grid using the CSS Grid module
 * @property {string} itemWidth=auto The width of each item (child element) in the Reel
 * @property {string} space=var(--s0) The space between Reel items (child elements)
 * @property {string} height=auto The height of the Reel itself
 * @property {boolean} noBar=false Whether to display the scrollbar
 */
export default class Reel extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Reel-${[this.itemWidth, this.height, this.space, this.noBar].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          height: ${this.height};
        }

        [data-i="${variantId}"] > * {
          flex: 0 0 ${this.itemWidth};
        }

        [data-i="${variantId}"] > img {
          height: 100%;
          flex-basis: auto;
          width: auto;
        }

        [data-i="${variantId}"] > * + * {
          margin-inline-start: ${this.space};
        }

        [data-i="${variantId}"].overflowing {
          ${!this.noBar ? `padding-bottom: ${this.space}` : ''}
        }

        ${
          this.noBar
            ? `
              [data-i="${variantId}"] {
                scrollbar-width: none;
              }

              [data-i="${variantId}"]::-webkit-scrollbar {
                display: none;
              }`
            : ''
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  toggleOverflowClass(elem: Element) {
    elem.classList.toggle('overflowing', this.scrollWidth > this.clientWidth)
  }

  get itemWidth() {
    return this.getAttribute('itemWidth') || 'auto'
  }

  set itemWidth(val) {
    this.setAttribute('itemWidth', val)
  }

  get height() {
    return this.getAttribute('height') || 'auto'
  }

  set height(val) {
    this.setAttribute('height', val)
  }

  get space() {
    return this.getAttribute('space') || 'var(--s0)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  get noBar() {
    return this.hasAttribute('noBar')
  }

  set noBar(val) {
    if (val) {
      this.setAttribute('noBar', '')
    } else {
      this.removeAttribute('noBar')
    }
  }

  static get observedAttributes() {
    return ['itemWidth', 'height', 'space', 'noBar']
  }

  connectedCallback() {
    this.render()
    if ('ResizeObserver' in window) {
      new ResizeObserver((entries) => {
        this.toggleOverflowClass(entries[0].target)
      }).observe(this)
    }

    if ('MutationObserver' in window) {
      new MutationObserver((entries) => {
        if (entries[0].target instanceof Element) this.toggleOverflowClass(entries[0].target)
      }).observe(this, { childList: true })
    }
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('reel-l')) {
  customElements.define('reel-l', Reel)
}
