import Cookies from 'universal-cookie'
import React, { useCallback, useState } from 'react'
import { GtmHelper } from 'shared'
import { InView } from 'react-intersection-observer'
import { ProjectTeaser } from 'betterplace-react-components'
import { ProjectTeaserWithTrackingProps } from './types'
const cookies = new Cookies()

export const ProjectTeaserWithTracking = ({
  index,
  project,
  teaserTracking,
  trackingPath,
  ...teaserProps
}: ProjectTeaserWithTrackingProps) => {
  const [tracked, setTracked] = useState(false)

  const handleClick = useCallback(() => {
    GtmHelper.pushProjectClicks(project, index + 1, trackingPath)
    if (teaserTracking) {
      const expires = new Date()
      expires.setTime(expires.getTime() + 3600 * 1000 * 3)
      cookies.set(`teaser-tracker-p${project.id}`, teaserTracking, { path: '/', expires })
    }
  }, [index, project, teaserTracking, trackingPath])

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (!isVisible || tracked) return
      GtmHelper.pushProjectImpressions(project, index + 1, trackingPath)
      setTracked(true)
    },
    [index, project, tracked, trackingPath]
  )

  return (
    <InView as="div" className="w-full" onChange={handleVisibilityChange} fallbackInView>
      <ProjectTeaser project={project} textColor="black" onClick={handleClick} {...teaserProps} />
    </InView>
  )
}
