import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { FormCentsInputProps } from './types'
import { parseCents, sanitizeMoney } from 'shared'

export const FormCentsInput = ({ field, form, hint, ...props }: FormCentsInputProps): JSX.Element => {
  const error = form.touched[field.name] && form.errors[field.name]

  const [value, setValue] = useState(sanitizeMoney((form.initialValues[field.name] / 100).toString()))
  const onChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    form.setFieldValue(field.name, parseCents(event.target.value))
    setValue(sanitizeMoney(event.target.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBlurred: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    // touchedness must be set manually if onBlur is overridden
    form.setFieldTouched(field.name, true)
    if (!event.target.value) return
    const valueWithoutLastDelimiter = event.target.value.replace(/[.,]+$/, '')
    form.setFieldValue(field.name, parseCents(valueWithoutLastDelimiter))
    setValue(sanitizeMoney(valueWithoutLastDelimiter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames('form-group', props.groupClass)}>
      <label className="control-label" htmlFor={field.name}>
        {props.label}
      </label>

      <div className="input-group">
        <input
          className={classNames('form-control', {
            'field-with-error': form.errors.amount_cents,
          })}
          maxLength={8}
          size={8}
          type="text"
          inputMode="decimal"
          value={value}
          // Todo: Make onChanged and onBlurred reusable in different components
          onChange={onChanged}
          onBlur={onBlurred}
        />
        <div className="input-group-append">
          <span className="input-group-text">€</span>
        </div>
      </div>
      {error && <div className="help-inline">{error}</div>}
      {hint && <small className="form-text text-muted">{hint}</small>}
    </div>
  )
}
