import React, { useCallback } from 'react'
import { ColorChangeHandler } from '../color_input/types'
import { ColorInput, FormGroup } from 'shared'
import { FormColorInputProps } from './types'
import { connect } from 'formik'

export const FormColorInput: React.ComponentType<FormColorInputProps> = connect(
  ({ field, form, label, formik: { setFieldValue }, transparentOption = false, ...props }) => {
    const onColorChange: ColorChangeHandler = useCallback(
      (value: string) => {
        setFieldValue(field.name, value === '#00000000' ? 'transparent' : value.replace('#', ''))
      },
      [field.name, setFieldValue]
    )
    return (
      <FormGroup field={field} form={form} label={label} {...props}>
        <ColorInput
          onColorChange={onColorChange}
          transparentOption={transparentOption}
          value={field.value || 'transparent'}
          label={label}
        />
      </FormGroup>
    )
  }
)
