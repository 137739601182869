import React from 'react'
import classNames from 'classnames'
import styles from './admin_box.module.css'

type AdminBoxVariant = 'dark' | 'light'
type AdminBoxProps = {
  children?: React.ReactNode
  linkUrl?: string
  linkText?: string
  variant?: AdminBoxVariant
}

export const AdminBox = ({ children, linkUrl, linkText, variant }: AdminBoxProps) => {
  return (
    <div data-testid="admin-box" className={classNames([styles.adminBox, variant === 'dark' && styles.variantDark])}>
      <div className={styles.header}>{i18n.t('shared.admin_box.header')}</div>
      <div className={styles.content}>
        {children}
        {linkText && linkUrl && (
          <a href={linkUrl} className="btn btn-large btn-primary">
            {linkText}
          </a>
        )}
      </div>
    </div>
  )
}
