import React from 'react'
import { NoticeProps } from './types'

export const Notice = ({ message, type }: NoticeProps): JSX.Element => {
  if (!type) type = 'error'

  return (
    <div className={`bpc-notice bpc-notice--${type}`}>
      <div className="flex justify-between">
        {typeof message === 'string' ? (
          message
        ) : (
          <ol>
            {message.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ol>
        )}
      </div>
    </div>
  )
}
