import React, { useCallback, useState } from 'react'
import { JSONFetcher } from 'shared'
import { SubscriptionToggleProps } from './types'

export const SubscriptionToggle = (props: SubscriptionToggleProps): JSX.Element => {
  const id = `subscription-toggle-${props.id}`
  const [value, setValue] = useState(props.initialValue === 'true')
  const handleChange = useCallback(() => {
    JSONFetcher.put({
      url: props.url,
      body: { mailing_subscription: { active: !value } },
      success: (response: { success?: boolean }) => {
        if (response.success) {
          setValue(!value)
        }
      },
    })
  }, [props.url, value])

  return (
    <form className="simpleform simple_form edit_mailing_subscription">
      <div className="generic-toggle-button small">
        <label htmlFor={id} className="checkbox toggle candy">
          <input id={id} type="checkbox" value="1" checked={value} onChange={handleChange} />
          <p>
            <span>{{ en: 'Yes', de: 'Ja' }[i18n.locale]}</span>
            <span>{{ en: 'No', de: 'Nein' }[i18n.locale]}</span>
          </p>
          <button className="btn btn-primary slide-button" />
        </label>
      </div>
    </form>
  )
}
