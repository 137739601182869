import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const PlatformAdminUI = () => {
  try {
    initPlatformAdminUI()
  } catch (e) {
    /* just don't crash old browsers */
  }
}

const initPlatformAdminUI = () => {
  document.querySelectorAll('[data-behavior~="platform-admin-quickinfo"]').forEach(initQuickinfo)

  document.querySelectorAll('[data-behavior="highlight-labels-for-testing"]').forEach(initLabelHighlighter)

  document.querySelectorAll('[data-behavior="toggle-ga-debug-cookie"]').forEach(initGaDebugToggle)

  document.querySelectorAll('[data-behavior="write-ga-tracking-path"]').forEach(writeGaTrackingPath)

  cookies.get('betterplace_backoffice_user') && initAdminBoxes()
}

const initQuickinfo = (quickinfo: Element) => {
  const collapse = () => quickinfo.classList.toggle('bpc-quickinfo--collapsed')

  if (window.innerWidth <= 450) {
    collapse()
  }

  const togglers: NodeListOf<HTMLElement> = quickinfo.querySelectorAll('[data-behavior="collapse-admin-boxes"]')
  togglers.forEach((toggler) => {
    toggler.addEventListener('click', (event) => {
      event.preventDefault()
      collapse()
    })
  })
  const closers: NodeListOf<HTMLElement> = quickinfo.querySelectorAll('[data-behavior="close-admin-boxes"]')

  closers.forEach((closer) => {
    closer.addEventListener('click', (event) => {
      event.preventDefault()
      const nodes: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="platform-admin-quickinfo"]')
      nodes.forEach((adminBox) => {
        adminBox.style.display = 'none'
      })
    })
  })
}

const initLabelHighlighter = (highlighter: Element) => {
  highlighter.addEventListener('click', () => {
    document.querySelectorAll('label').forEach((label) => {
      label.classList.toggle('highlight-labels-for-testing')
    })
    highlighter.classList.toggle('bg-purple-600')
  })
}

const initGaDebugToggle = (gaDebugToggler: Element) => {
  if (cookies.get('ga_debug')) {
    gaDebugToggler.classList.add('bg-purple-600')
  }

  gaDebugToggler.addEventListener('click', () => {
    if (cookies.get('ga_debug')) {
      cookies.remove('ga_debug')
    } else {
      const oneHourInSeconds = 3600
      cookies.set('ga_debug', 'true', { maxAge: oneHourInSeconds * 6 })
    }
    gaDebugToggler.classList.toggle('bg-purple-600')
  })
}

const writeGaTrackingPath = (gaTrackingPathReceiver: Element) => {
  setInterval(function () {
    gaTrackingPathReceiver.textContent = window.gaTrackingPath ?? null
  }, 1000)
}

const initAdminBoxes = () => {
  const nodes: NodeListOf<HTMLElement> = document.querySelectorAll('[data-permission="admin"]')
  nodes.forEach(async (box) => {
    box.style.display = '' // Always un-hide the admin box, even if it is not remote
    if (!box.dataset.source) return false
    const response = await fetch(box.dataset.source)
    if (!response.ok) return false
    box.innerHTML = await response.text() // not a JSON endpoint (also used in server-rendered views)
    // FYI, in order to use our modal_link helper in quicklinks-partials
    // the mounting of the modal need to be re-initialised after the AJAX quickinfo shows.
    // However, we should scope mountOnDataBehavior so only modals inside this quicklinks scope
    // get remounted.
    //
    // import { mountOnDataBehavior, ModalLink } from 'shared'
    // mountOnDataBehavior(ModalLink, 'modal-link')
    box.querySelectorAll('[data-behavior~="platform-admin-quickinfo"]').forEach(initQuickinfo)
  })
}
