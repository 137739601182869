/**
 * Every Layout: The Frame
 * @source https://every-layout.dev/layouts/frame/
 * @module frame-l
 * @description
 * A custom element for augmenting image ratios
 * @property {string} ratio=16:9 The element's aspect ratio
 */
export default class Frame extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      if (this.children.length !== 1) {
        console.warn('<frame-l> elements should have just one child element')
      }
      const variantId = `Frame-${[this.ratio].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const ratio = this.ratio.split(':')
      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          aspect-ratio: ${ratio[0]} / ${ratio[1]};
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get ratio() {
    return this.getAttribute('ratio') || '16:9'
  }

  set ratio(val) {
    this.setAttribute('ratio', val)
  }

  static get observedAttributes() {
    return ['ratio']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('frame-l')) {
  customElements.define('frame-l', Frame)
}
